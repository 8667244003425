/* eslint-disable */
import { useState, useEffect, HTMLAttributes } from 'react'
import {
  ReadonlyBigCAccessToken,
  SHOP_HASH,
  SHOP_ORIGIN,
} from '@/services/Configuration'
import {
  getSiteWideInitialProps, useClientSideCartAndUser, SiteWideInitialPropsFailure, SiteWideSSRProps,
} from '@/services/getSiteWideInitialProps'
import type { NextPage, NextPageContext } from 'next'
import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import dynamic from 'next/dynamic'
import { bloomreachContentPageViewPixel, bloomreachHomePagePixel } from '@/components/ScriptIntegrations/BloomreachEvents'
import { timePromiseAndLog } from '@/helpers/timePromiseAndLog'
import {
  LogType,
  LogLevel,
  Log,
} from '@/services/cscLog'

const Default = dynamic(import('@/layouts/default'))

const DESKTOP_BREAKPOINT = 768
const registered: Array<(b: boolean) => void> = []

const register = (functor) => registered.push(functor)
const deregister = (functor) => () => {
  registered.splice(registered.indexOf(functor), 1)
}
const bound = false

const listener = () => {
  const desktop = window.innerWidth >= DESKTOP_BREAKPOINT
  registered.forEach((setter) => setter(desktop))
  return desktop
}

const bind = (setDesktop: (listener: unknown) => void) => {
  if (!bound && typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
    setDesktop(listener())
    window.addEventListener('resize', listener)
  }
}

interface ExternalPageProps extends SiteWideSSRProps {
  pageHtml?: string
  pageTitle?: string
  pageDescription?: string,
  isHomePage? : boolean
}

// eslint-disable-next-line react/jsx-props-no-spreading
const PageContentDiv: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => <div {...props} />
const ExternalPage: NextPage<ExternalPageProps> = ({
  pageHtml = '',
  pageTitle = '',
  pageDescription = '',
  sitewideBanner,
  megaNav,
  isHomePage,
  eventBanner,
  footer,
}) => {
  const { cart, setCart, user, reloadCustomer } = useClientSideCartAndUser()
  const [isDesktop, setDesktop] = useState(false)

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a')
  }

  useEffect(() => {
    bind(setDesktop)
    register(setDesktop)
    return deregister(setDesktop)
  }, [])

  useEffect(() => {
    if(isHomePage){
      bloomreachHomePagePixel()
    }else{
      bloomreachContentPageViewPixel(pageTitle)
    }
  }, [])

  useEffect(() => {
    allPromisesWithRetries(() => [import('@/services/Cart/getCart')])
      .then(([{ getCart }]) => getCart())
      .then(setCart)
      .catch(error)
  }, [setCart])
  return (
    <Default
      reloadCustomer={reloadCustomer}
      user={user}
      sitewideBanner={sitewideBanner}
      megaNav={megaNav}
      meta={{ description: pageDescription, title: pageTitle }}
      cart={cart}
      onUpdateCart={setCart}
      PageContentDiv={PageContentDiv}
      eventBanner={eventBanner}
      maybeFooterFromContentful={footer}
    >
      <div
        style={{ marginTop: isDesktop ? '70px' : '50px' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: isDesktop ? pageHtml : pageHtml }}
        onClick={contentClickHandler}
      />
    </Default>
  )
}

type BigcommercePage = {
  url: string
  id: number
  channel_id: number
  name:string
  is_visible: boolean
  parent_id: number
  sort_order: number
  type: 'page' | 'raw' | 'contact_form' | 'feed' | 'link' | 'blog'
  is_homepage: boolean
  is_customers_only:boolean
  meta_title: string
  meta_description: string
}

type BigcommercePagesList = Array<BigcommercePage>

export type BigcommercePageResponse = {
  data: BigcommercePagesList
  meta: {
    pagination: {
      total: number
      count: number
      per_page: number
      current_page: number
      total_pages: number
    }
  }
}

export type BigcommercePageResource = {
  body: string
  meta_title: string
  meta_description: string
}

const getPageByPageNumber = async (pageNumber: number): Promise<BigcommercePageResponse> => {
  const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
  return (await Axios.get<BigcommercePageResponse>(`https://api.bigcommerce.com/stores/${SHOP_HASH}/v3/content/pages?limit=10s&page=${pageNumber}`, { headers: { 'X-Auth-Token': ReadonlyBigCAccessToken } })).data
}

const findPageResourceInList = (bigcommercePagesList: BigcommercePagesList) => (
  (pageResourceUrl: string): BigcommercePage | null => (
    bigcommercePagesList.find((page) => page.url === `/${pageResourceUrl}`) || null
  )
)

const getPageXmlByWithPageId = async (pageId: string | number): Promise<BigcommercePageResource> => {
  const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
  const rawXml = (await Axios.get<BigcommercePageResource>(`https://api.bigcommerce.com/stores/${SHOP_HASH}/v2/pages/${pageId}`, { headers: { 'X-Auth-Token': ReadonlyBigCAccessToken } })).data
  return rawXml
}

const findHomePage = <T extends { is_homepage: boolean }>(list: T[]) => (
  list.find((page) => page.is_homepage === true) || null
)
const findHomePageResourceInListsOfPages = (bigcommercePagesList: BigcommercePagesList) => (
  typeof findHomePage(bigcommercePagesList) === 'object'
    ? findHomePage(bigcommercePagesList)?.id || null
    : null
)

const getPageResourceIdFromListsOfPages = (
  bigcommercePagesLists: BigcommercePagesList[],
  pageResourceUrl: string,
) => (
  pageResourceUrl === ''
    ? { id: findHomePageResourceInListsOfPages(bigcommercePagesLists.flat()), isHomePage: true }
    : { id: findPageResourceInList(bigcommercePagesLists.flat())(pageResourceUrl)?.id || null, isHomePage: false }
);

const getPageResource = async (pageResourceUrl: string) => {
  const { data: list, meta: { pagination } } = await getPageByPageNumber(1);
  let pageData = getPageResourceIdFromListsOfPages([list], pageResourceUrl);
  if (pageData.id === null) {
    const extraPages = await Promise.all(
      // Create an array of page numbers, starting at 2, and ending at the total number of pages
      Array.from({ length: pagination.total_pages - 1 }, (_, index) => index + 2)
        // Map each page number to a promise that resolves to the page data
        .map(getPageByPageNumber),
    )
    pageData = getPageResourceIdFromListsOfPages(extraPages.map(({ data }) => data), pageResourceUrl);

  }

  if (pageData.id === null) {
    throw new Error('Page not found in catalog');
  }

  const rawXml = await getPageXmlByWithPageId(pageData.id);
  return { rawXml, isHomePage: pageData.isHomePage };
};

export const getInitialProps = async (ctx: NextPageContext) => {
  const pageResourceUrl = (
    `${ctx.asPath || ''}`
      .split('?')[0]
      ?.split('/')
      ?.reduce((acc, item, index) => (
        index > 0 ? `${acc}${index > 1 ? '/' : ''}${item}` : ''
      ), '')
  )
  try {
    /* Cache results, maybe timeout for 5 min / 15 min */
    /* API call rate limits and caps are a sticking point and risk */
    const [
      siteWideProps,
      pageObj,
    ] = await Promise.all([
      timePromiseAndLog('ExternalPage.getInitialProps.getSiteWideInitialProps')(getSiteWideInitialProps(ctx)),
      timePromiseAndLog('ExternalPage.getInitialProps.getPageResource')(getPageResource(pageResourceUrl)),
    ])
    return {
      ...siteWideProps,
      pageHtml: pageObj.rawXml.body,
      pageTitle: pageObj.rawXml.meta_title,
      pageDescription: pageObj.rawXml.meta_description,
      isHomePage : pageObj.isHomePage
    }
  } catch (e) {
    log(`No page found for ${pageResourceUrl}`)
    Log({
      level: LogLevel.ERROR,
      type: LogType.ServerRuntimeError,
      name: 'Failed to initialize external page',
      contextMap: {
        errorString: String(e),
        path: ctx.asPath || '',
        queryParams: ctx.query,
      }
    })
    error(`Failed to load external page: ${String(e)}`, e)
    const params = new URLSearchParams({ home: `${String(ctx.asPath || '') || ''}`.replace(/\//g, '') })
    const location = `${SHOP_ORIGIN}/PageNotFound?${String(params)}`
    if (process.browser && typeof window !== 'undefined' && window.location.href) {
      window.location.href = location
    } else if (ctx.res) {
      // Tell 302 proxy not to cache response
      // https://docs.layer0.co/guides/caching#section_caching_private_responses
      ctx.res?.setHeader?.('cache-control', 'public, must-revalidate, proxy-revalidate, max-age=0, s-maxage=0')
      ctx.res?.writeHead?.(302, { location })
      ctx.res?.end?.()
    }
    // At this point the page should have reloaded on the 404
    // We no longer return props for a failed scenario
    // This allow us to do strong types on the component props
    // While the page is loading, code execution will
    // continue, so we'll await a never-resolving
    // promise to make sure our page never
    // gets rendered.
    // await new Promise((resolve) => setTimeout(resolve, 15000)) // Max 15s to redirect
    return SiteWideInitialPropsFailure
  }
}

ExternalPage.getInitialProps = getInitialProps

export default ExternalPage
