import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { BLOOMREACH_ACCOUNT_ID, IS_BLOOMREACH_TEST_ENV } from '@/services/Configuration'
import type { BreadcrumbsType } from '@/types/ElasticSearch/SearchAPIResponse'
import type { Product } from '@/types/SearchAPI/SAPIProduct'
import type { StandardLineItem } from '@/types/ShopFront/CheckoutStandards'
import { escapeQuotes } from '../../helpers/getQueryWithValidCharacters'

const generateBloomreachBaseScript = (customScript?: string) => {
  const baseScript = `
    var brtrk = document.createElement('script');
    brtrk.type = 'text/javascript';
    brtrk.async = true;
    brtrk.src = "//cdn.brcdn.com/v1/br-trk-${BLOOMREACH_ACCOUNT_ID}.js";
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(brtrk, s);
    var br_data = br_data || {};
    br_data.acct_id = ${BLOOMREACH_ACCOUNT_ID};
    br_data.test_data = '${IS_BLOOMREACH_TEST_ENV}';
  `
  return customScript ? `${baseScript} ${customScript}` : baseScript
}
export const bloomreachConversionEvent = (
  customerId: number,
  orderId: string,
  orderTotal: number,
  lineItems: StandardLineItem[],
) => {
  const updatedLineItems = lineItems.map(({
    sku: prod_id,
    sku,
    subtotalPrice: price,
    ...rest
  }) => ({
    prod_id,
    sku,
    price,
    ...rest,
  }))
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('type', 'text/javascript')
  let scriptContent = `
      ${generateBloomreachBaseScript()}
      br_data.ptype = 'conversion';
  `
  if (customerId !== undefined && customerId !== 0) {
    scriptContent += `br_data.user_id = ${customerId};`
  }
  scriptContent += `
      br_data.is_conversion = ${1};
      br_data.basket_value = ${orderTotal};
      br_data.order_id = ${orderId};
      br_data.basket = {
          'items': ${JSON.stringify(updatedLineItems)},
      };
  `
  bloomreachScript.textContent = scriptContent
  document.body.appendChild(bloomreachScript)
}

export const bloomreachSearchPagePixel = (
  title,
  userId,
) => {
  const escapedTitle = escapeQuotes(title as string)
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('type', 'text/javascript')
  bloomreachScript.setAttribute('id', 'SearchPageViewPixel')
  bloomreachScript?.parentNode?.removeChild(bloomreachScript)
  bloomreachScript.textContent = `
        ${generateBloomreachBaseScript()}
        br_data.ptype = "search";
        ${userId !== undefined && userId !== 0 ? `br_data.user_id = '${String(userId)}';` : ''}
        br_data.search_term = "${escapedTitle}"; 
      `
  document.body.appendChild(bloomreachScript)
}

export const bloomreachSearchEvent = (
  searchString: string,
  userId: number | undefined,
) => {
  if (window.BrTrk) {
    const searchData: {
      q: string
      catalogs: { name: string }[], user_id: number | undefined
    } = {
      q: '',
      catalogs: [],
      user_id: 0,
    }
    searchData.q = searchString
    if (userId !== undefined && userId !== 0) {
      searchData.user_id = userId
    }
    searchData.catalogs = [{ 'name': 'onekingslane' }]
    window.BrTrk?.getTracker?.().logEvent('suggest', 'submit', searchData, {}, true)
  }
}

export const bloomreachAddToCartEvent = (
  sku: string,
  productId: number | string,
) => {
  if (window.BrTrk) {
    window.BrTrk?.getTracker?.().logEvent('cart', 'click-add', { 'prod_id': productId, 'sku': sku })
  }
}

export const bloomreachPageViewPixel = (
  product: Product | undefined,
  customerId: number | undefined,
  sku: string | undefined,
) => {
  if (!product) {
    return
  }
  const prodId = product.sku.slice(1)
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('id', 'PDPPixelScript')
  bloomreachScript.textContent = `
      if (document.referrer !== document.location.href) {
        ${generateBloomreachBaseScript()}
          br_data.ptype = "product";
          br_data.prod_name = '${product?.name.replace(/'/g, "\\'")}';
          br_data.prod_id = '${prodId}';
          br_data.sku = '${String(sku)}';
  `
  if (customerId !== undefined && customerId !== 0) {
    bloomreachScript.textContent += `br_data.user_id = '${customerId}';`
  }
  bloomreachScript.textContent += `
  }
  `
  const script = document.getElementById('PDPPixelScript')
  if (script === null) {
    document.body.appendChild(bloomreachScript)
  }
}

export const bloomreachContentPageViewPixel = (
  pageTitle?,
) => {
  const bloomreachScript = document.createElement('script')
  bloomreachScript.textContent = `
          ${generateBloomreachBaseScript()}
          br_data.catalogs = [ { name : 'onekingslane_discovery' } ];
          br_data.item_name = '${String(pageTitle)}';
          br_data.ptype = "content";
        `
  document.body.appendChild(bloomreachScript)
}

export const bloomreachSuggestEventPixel = (
  searchString: string | RegExp,
  searchSuggestion: string,
  userId: number | undefined,
) => {
  const searchData: {
    q: string, aq: string | RegExp,
    catalogs: { name: string }[], user_id?: number | undefined
  } = {
    q: '',
    aq: '',
    catalogs: [],
  }
  searchData.q = searchSuggestion
  searchData.aq = searchString
  searchData.catalogs = [{ 'name': 'onekingslane' }]
  if (userId !== undefined && userId !== 0) {
    searchData.user_id = userId
  }

  if (window.BrTrk) {
    window.BrTrk?.getTracker?.().logEvent('suggest', 'click', searchData, {}, true)
  }
}

export const bloomreachCategoryPagePixel = (
  breadcrumbs?: BreadcrumbsType[],
) => {
  if (!breadcrumbs || breadcrumbs?.length === 0) {
    return
  }
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('type', 'text/javascript')
  bloomreachScript.textContent = `
  if (document.referrer !== document.location.href){
        ${generateBloomreachBaseScript()}
          br_data.ptype = "category";
          br_data.cat_id = ${breadcrumbs?.slice(-1)[0].id};
          br_data.cat = '${breadcrumbs?.map((obj) => escapeQuotes(obj.name)).join('|')}';
  }`
  document.body.appendChild(bloomreachScript)
}

export const bloomreachHomePagePixel = () => {
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('type', 'text/javascript')
  bloomreachScript.textContent = `
  if (document.referrer !== document.location.href){
        ${generateBloomreachBaseScript()}
          br_data.ptype = "homepage";  }`
  document.body.appendChild(bloomreachScript)
}

export const bloomreachOtherPagePixel = () => {
  const bloomreachScript = document.createElement('script')
  bloomreachScript.setAttribute('type', 'text/javascript')
  bloomreachScript.textContent = `
        ${generateBloomreachBaseScript()}
        br_data.ptype = "other";
        `
  document.body.appendChild(bloomreachScript)
}

export const sendDataToBloomreach = async (eventType, query?, name?) => {
  const [
    { getCustomer },
  ] = await allPromisesWithRetries(() => [
    import('@/helpers/graphql'),
  ])
  const { customer } = await getCustomer()
  if (eventType === 'SearchPage') {
    bloomreachSearchPagePixel(query, customer?.entityId)
  } else if (eventType === 'SuggestEvent') {
    bloomreachSuggestEventPixel(query, name, customer?.entityId)
  } else {
    bloomreachSearchEvent(query, customer?.entityId)
  }
}
