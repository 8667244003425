/* eslint-disable no-console */
import { toLiteral } from '@/helpers/FP'

export enum LogType {
  AuthorizationPrecheck = 'AuthorizationPrecheck',
  ResponseDispatched = 'ResponseDispatched',
  RequestingData = 'RequestingData',
  ProcessingData = 'ProcessingData',
  UserInputError = 'UserInputError',
  ServerRuntimeError = 'ServerRuntimeError',
  SuccessfulRequestFulfillment = 'SuccessfulRequestFulfillment',
  RequestReceived = 'RequestReceived',
}

export enum LogLevel {
  FATAL = 'FATAL',
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export type LogContextObjectValue = string | number | null | undefined | boolean

export type LogContextObject = {
  key: string;
  value: LogContextObjectValue;
}

export type LogContextMap = {
  [key: string]: unknown;
}

export type LogEvent = {
  level: LogLevel;
  type: LogType;
  name: string;
  context?: LogContextObject[];
  contextMap?: LogContextMap;
}

const NORMALLY_ACCEPTABLE_LEVELS = [LogLevel.FATAL, LogLevel.ERROR, LogLevel.WARN, LogLevel.INFO]

export const Log = (logEvent: LogEvent) => {
  if (
    (process.env.ALLOW_DEBUG_EVENTS && process.env.NODE_ENV !== 'production' && logEvent.level === LogLevel.DEBUG)
    || NORMALLY_ACCEPTABLE_LEVELS.includes(logEvent.level)
  ) {
    console.log('%j', { logEvent })
  }
}

export const toContext = (o: unknown): LogContextMap =>
  (typeof o === 'object' && o !== null
    ? Object.entries(o).reduce((acc, [key, value]) => ({ ...acc, [key]: toLiteral(value) }), {})
    : { context: JSON.stringify(o) })

export const logger = (
  level: LogLevel,
) => (name: string) => (type: LogType) => (contextMap: unknown) =>
  Log({
    level,
    name,
    type,
    contextMap: toContext(contextMap),
  })

export const log = logger(LogLevel.INFO)
export const debug = logger(LogLevel.DEBUG)
export const error = logger(LogLevel.ERROR)
export const warn = logger(LogLevel.WARN)
export const fatal = logger(LogLevel.FATAL)
