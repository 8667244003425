import xss from 'xss'
import type { QueryParamsType } from '@/types'

const LEGAL_FILTER_CHARACTER_SET = /^[\w \-+,$'"&%’”./`]+$/
type Query = {
  [key: string]: string | string[] | undefined
}

export const removeIllegalCharacters = (s: string) => s.replace(new RegExp(`[^${LEGAL_FILTER_CHARACTER_SET.source}]`, 'g'), '')
export const getQueryWithValidCharacters = (queryWithoutSlug: Query) => {
  const copied = { ...queryWithoutSlug }
  const hasIllegalChar = (str: string) => !LEGAL_FILTER_CHARACTER_SET.test(str)
  const deleteKey = (key: string) => delete copied[key]
  Object.keys(copied).forEach((key) => {
    const value = copied[key]
    if (!value) {
      deleteKey(key)
    } else if (Array.isArray(value)) {
      deleteKey(key)
    } else if (hasIllegalChar(value)) {
      deleteKey(key)
    }
  })
  return (copied as QueryParamsType)
}

export const xssEscape = (s: string) => xss(s)
export const getQueryXssEscaped = (query: Query) => {
  const escaped = Object.entries(query).reduce((hash, [key, val]) => {
    if (val && !Array.isArray(val)) {
      // eslint-disable-next-line no-param-reassign
      hash[key] = xssEscape(val)
    }
    return hash
  }, {} as QueryParamsType)
  return escaped
}

export const escapeQuotes = (s: string) => s.replace('"', '\\"')
  .replace('\'', '\\\'')
  .replace('`', '\\`')

export default getQueryWithValidCharacters
